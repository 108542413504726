import _typeof from "C:/Users/UNI/Desktop/global-website/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4cc161d2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = ["id"];
import { ref, onMounted } from "vue";
import $ from "jquery";
import circlr from "@/utils/circlr-img";
export default {
  __name: 'index',
  props: {
    imgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    elId: {
      type: String,
      default: "circlr"
    },
    shopWindowId: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    }
  },
  emits: ["loadComplete"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var index = ref(0);
    var completeNum = ref(0);
    onMounted(function () {
      $(function () {
        var cir = circlr(props.elId, {
          scorll: true,
          customElId: props.elId
        });
      });
      setImg();
    });
    var setImg = function setImg() {
      props.imgList.map(function (item, index) {
        //创建Img
        var img = document.createElement("img");
        //添加到页面中
        $("#".concat(props.elId))[0].appendChild(img);
        //设置值
        if (_typeof(item) === "object") {
          img.setAttribute("data-src", item.url);
          checkImageLoad(item.url, index);
        } else {
          img.setAttribute("data-src", item);
          checkImageLoad(item, index);
        }
        img.setAttribute("class", "image");
      });
    };
    var checkImageLoad = function checkImageLoad(image, index) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", image, true);
      xhr.responseType = "blob";
      // xhr.onprogress = (event) => {
      //   if (event.lengthComputable) {
      //     const percentComplete = Math.round((event.loaded / event.total) * 100);
      //   }
      // };
      xhr.onload = function () {
        completeNum.value++;
        if (completeNum.value === props.imgList.length) {
          emits("loadComplete", props.shopWindowId);
        }
      };
      xhr.onerror = function () {
        if (completeNum.value <= props.imgList.length) {
          emits("loadComplete", props.shopWindowId);
        }
      };
      xhr.send();
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(["circlr-block pointer", [__props.type === 40 ? 'bg-position' : '']]),
        id: __props.elId,
        style: {
          "width": "480px",
          "height": "480px"
        }
      }, null, 10, _hoisted_2)]);
    };
  }
};